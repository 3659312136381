import Image from 'next/future/image';

import type { TypeClassName } from '@/components/types';
import type { TypeImageGraphQL } from '@/lib/graphql/types/basic';
import type { FunctionComponent } from 'react';

interface TypeHeroSimple extends TypeClassName {
  desktopImage: TypeImageGraphQL;
  mobileImage: TypeImageGraphQL;
}

/**
 * HeroWordpress
 */
export const HeroSimple: FunctionComponent<TypeHeroSimple> = ({
  desktopImage,
  mobileImage,
}: TypeHeroSimple) => (
  <>
    <div className="max-sm:hidden">
      <Image
        src={desktopImage.sourceUrl}
        alt={desktopImage.altText}
        width={desktopImage.mediaDetails.width}
        height={desktopImage.mediaDetails.height}
        className="w-full object-contain"
        draggable={false}
        priority
        quality={100}
      />
    </div>

    <div className="sm:hidden">
      <Image
        src={mobileImage.sourceUrl}
        alt={mobileImage.altText}
        width={mobileImage.mediaDetails.width}
        height={mobileImage.mediaDetails.height}
        className="w-full object-contain"
        draggable={false}
        priority
        quality={100}
      />
    </div>
  </>
);

HeroSimple.displayName = 'HeroSimple';
