import { useState } from 'react';
import { Splide } from '@splidejs/react-splide';
import { oneLine } from 'common-tags';

import { ArrowRight } from '@/icons';
import { IconButton } from '@/molecules';

import { toArray } from '@/lib/utils';
import styles from './SliderHero.module.css';

import type { Options } from '@splidejs/splide';
import type { FunctionComponent } from 'react';
import type { TypeSliderHeroProps } from './types';

/**
 * SliderHero
 */
export const SliderHero: FunctionComponent<TypeSliderHeroProps> = ({
  className = '',
  minHeight = '',
  children,
}: TypeSliderHeroProps) => {
  const childrenArray = toArray(children);
  const [slideConfig, setSlideConfig] = useState({
    caption: '',
    negative: false,
    index: 1,
    total: childrenArray.length,
  });
  const options: Options = {
    type: childrenArray.length === 1 ? 'slide' : 'loop',
    perPage: 1,
    perMove: 1,
    pagination: slideConfig.total > 1,
    drag: slideConfig.total > 1,
    arrows: slideConfig.total > 1,
    classes: {
      pagination: `splide__pagination ${styles['c-slider-hero__pagination']}`,
    },
    lazyLoad: 'nearby',
    autoplay: slideConfig.total > 1,
    /**
     * Important! if the speed is changed you mush change the
     * CSS transition speed to the same value
     */
    speed: 400,
  };
  const updateSlideConfig = (
    slide: HTMLElement,
    index: number,
    total: number,
  ) =>
    setSlideConfig({
      caption: slide?.getAttribute('data-caption') ?? '',
      negative: slide?.getAttribute('data-negative') === 'true' ? true : false,
      index: index,
      total: total,
    });

  return (
    <>
      {/**
       * TODO: La versión que tenemos de Splide no tiene el prop Children ya que en React 17 se añadia automaticamente pero en la 18 no
       * Cuando actualicemos Splide a la última versión, podemos quitar el @ts-ignore
       * @eslint-disable-next-line @typescript-eslint/ban-ts-comment
       * @ts-ignore*/}
      <Splide
        options={options}
        className={oneLine`
        ${styles['c-slider-hero']}
        ${slideConfig.negative ? styles['c-slider-hero--negative'] : ''}
        ${className}
        ${minHeight}
      `}
        onMove={(splide, index) => {
          const slide = splide.Components.Elements.slides[index];
          if (slide) {
            updateSlideConfig(slide, index + 1, splide.length);
          }
        }}
        onMounted={(splide) => {
          const slide = splide.Components.Elements.slides[0];
          if (slide) {
            updateSlideConfig(slide, 1, splide.length);
          }
        }}
        onPaginationUpdated={(_, __, prev, curr) => {
          prev.li.className = '';
          curr.li.className = 'current_slide_li';
        }}
        {...(slideConfig.total > 1
          ? {
              renderControls: () => (
                <div
                  className={`splide__arrows ${styles['c-slider-hero__arrows']}`}
                >
                  <IconButton
                    className={oneLine`${styles['c-slider-hero__arrow']} ${styles['c-slider-hero__arrow--next']} splide_arrow splide__arrow--next`}
                    size="medium"
                    style="solid"
                    aria-label="Slide Siguiente"
                  >
                    <ArrowRight color="#FFFFFF" />
                  </IconButton>

                  <IconButton
                    className={oneLine`${styles['c-slider-hero__arrow']} ${styles['c-slider-hero__arrow--prev']} splide_arrow splide__arrow--prev`}
                    size="medium"
                    style="solid"
                    aria-label="Slide Anterior"
                  >
                    <ArrowRight color="#FFFFFF" />
                  </IconButton>
                </div>
              ),
            }
          : {})}
      >
        {childrenArray}
      </Splide>
    </>
  );
};
