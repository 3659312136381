import dynamic from 'next/dynamic';

import { ConditionalWrapper } from '@/hoc';
import { NextLink } from '@/atoms';
import { WithPaperBorder } from '@/molecules';
import { SliderHero } from '@/organisms';
import { HeroComplete, HeroSimple } from '@/templates';

import { removeBaseURL } from '@/lib/utils';

import type { FunctionComponent } from 'react';

const WhyToBuy = dynamic(
  () => import('@/templates/flexible-content/PorqueComprar/PorqueComprar'),
  {
    ssr: true,
  },
);

/**
 * HeroSlider
 */
export const HeroSlider: FunctionComponent<{ hero: any }> = ({
  hero,
}: {
  hero: any;
}) => (
  <>
    <WithPaperBorder
      border={hero.papelRasgado?.border}
      borderColor={hero.papelRasgado?.borderColor}
      borderHeight={hero.papelRasgado?.borderHeight}
      top={
        hero.papelRasgado?.zona === 'top' ||
        hero.papelRasgado?.zona === 'top bottom'
      }
      bottom={
        hero.papelRasgado?.zona === 'bottom' ||
        hero.papelRasgado?.zona === 'top bottom'
      }
      className="relative z-[1]"
    >
      {hero.mode === 'simple' && (
        <SliderHero>
          {hero.slideSimple
            .filter((slide: any) => slide.desktopImage && slide.mobileImage)
            .map((slide: any, index: number) => (
              <SliderHero.Slide
                key={index}
                negative={slide.negative}
                caption={slide?.caption}
                className="w-full"
              >
                <ConditionalWrapper
                  condition={Boolean(slide?.link)}
                  wrapper={(children) => (
                    <NextLink
                      href={{
                        pathname: removeBaseURL(slide.link?.url ?? ''),
                      }}
                    >
                      <a>{children}</a>
                    </NextLink>
                  )}
                >
                  <HeroSimple
                    desktopImage={slide.desktopImage}
                    mobileImage={slide.mobileImage}
                  />
                </ConditionalWrapper>
              </SliderHero.Slide>
            ))}
        </SliderHero>
      )}

      {hero.mode === 'complete' && hero.slide && (
        <SliderHero>
          {hero.slide.map((slide: any, index: number) => (
            <SliderHero.Slide
              key={index}
              negative={slide.negative}
              caption={slide?.caption}
              className="w-full"
            >
              <ConditionalWrapper
                condition={Boolean(slide?.link)}
                wrapper={(children) => (
                  <NextLink
                    href={{
                      pathname: removeBaseURL(slide.link?.url ?? ''),
                    }}
                  >
                    <a>{children}</a>
                  </NextLink>
                )}
              >
                <HeroComplete slide={slide} variant={hero.variant} />
              </ConditionalWrapper>
            </SliderHero.Slide>
          ))}
        </SliderHero>
      )}
    </WithPaperBorder>

    {hero?.showPorqueComprar && <WhyToBuy />}
  </>
);

HeroSlider.displayName = 'HeroSlider';
