import { SplideSlide } from '@splidejs/react-splide';

import type { FunctionComponent } from 'react';
import type { TypeSlideProps } from './types';

/**
 * Slide
 */
export const Slide: FunctionComponent<TypeSlideProps> = ({
  className,
  caption,
  negative = false,
  children,
}: TypeSlideProps) => (
  <SplideSlide
    {...(className && { className })}
    data-negative={negative}
    data-caption={caption}
  >
    <children.type
      {...children.props}
      className={`h-full ${children.props.className}`}
      classNameWrapper={`pb-12 ${children.props.className}`}
      negative={negative}
    />
  </SplideSlide>
);
