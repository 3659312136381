import { Fragment } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/future/image';
import { oneLine } from 'common-tags';

import { PaperTextureRight } from '@/icons';
import { NextLink } from '@/atoms';
import { Button, Hero } from '@/molecules';

import { containerTransformer } from '@/lib/graphql/transformers';
import { gtmPush, normalizeItem, removeBaseURL } from '@/lib/utils';

import type { TypeClassName, WpButton } from '@/components/types';
import type { TypeSrcImageGraphQL } from '@/lib/graphql/types';
import type {
  TypeAlignGraphQL,
  TypeImageGraphQL,
  TypeJustifyGraphQL,
  TypeTextAlignGraphQL,
} from '@/lib/graphql/types/basic';
import type { TypeHeroProps } from '@/molecules/hero';
import type { FunctionComponent } from 'react';

const PaperTextureBottom = dynamic(
  () =>
    import('@/icons/PaperTextureBottom').then((mod) => mod.PaperTextureBottom),
  { ssr: false },
);

const PaperTextureTop = dynamic(
  () => import('@/icons/PaperTextureTop').then((mod) => mod.PaperTextureTop),
  { ssr: false },
);

interface TypeHeroWordpresColumn {
  overlay: TypeHeroProps['overlay'];
  justify: TypeJustifyGraphQL;
  align: TypeAlignGraphQL;
  colorDeFondo: string;
  textAlign: TypeTextAlignGraphQL;
  srcset: TypeSrcImageGraphQL[];
  imagenBase: TypeImageGraphQL;
  negative?: TypeHeroProps['negative'];
  logo: TypeImageGraphQL;
  content: any;
  separadorRasgado: boolean;
  colorSeparador: string;
}

interface TypeHeroWordpres extends TypeClassName {
  columns: TypeHeroWordpresColumn[];
  separadorRasgado: boolean;
  colorSeparador: string;
}

const HeroVariantMinHeight = new Map<string, string>([
  ['default', 'min-h-[490px] md:min-h-[340px]'],
  ['slider', 'min-h-[500px] md:min-h-[488px]'],
  ['marcas', 'min-h-[490px] md:min-h-[340px]'],
  ['categoria', 'min-h-[186px] md:min-h-[232px]'],
  ['dynamic', 'sm:min-h-[340px]'],
]);
const HeroVariantColumnOrder = new Map<string, string>([
  ['default', 'flex-col'],
  ['slider', 'flex-col'],
  ['marcas', 'flex-col-reverse'],
  ['categoria', 'flex-col'],
  ['dynamic', 'flex-col'],
]);

/**
 * HeroWordpress
 */
export const HeroComplete: FunctionComponent<{
  slide: TypeHeroWordpres;
  variant: string;
}> = ({ slide, variant }: { slide: TypeHeroWordpres; variant: string }) => (
  <div
    className={oneLine`
     ${HeroVariantColumnOrder.get(variant)}
     relative flex h-full w-full flex-col md:flex-row
     ${HeroVariantMinHeight.get(variant)}
   `}
  >
    {slide.columns.map((column: TypeHeroWordpresColumn, index: number) => {
      const data = containerTransformer({ ...column });

      return (
        <div
          key={index}
          className={oneLine`
          relative flex w-full flex-col
          ${index === 0 ? 'h-full' : ''}
          ${index === 1 ? 'min-h-[240px] md:h-full' : ''}`}
          {...(column.colorDeFondo
            ? {
                style: {
                  backgroundColor: column.colorDeFondo,
                },
              }
            : {})}
        >
          {data.backgroundImage && typeof data.backgroundImage !== 'string' && (
            <div
              className={`${
                variant === 'dynamic' && slide.columns.length === 1
                  ? 'sm:absolute'
                  : 'absolute'
              } inset-0`}
            >
              {data.backgroundImage.srcset.map((source, index) => {
                let className = '';

                if (
                  !data.backgroundImage ||
                  typeof data.backgroundImage === 'string'
                ) {
                  return;
                }

                if (index === 0) {
                  className = `hidden ${source.media}:block`;
                } else if (index === data.backgroundImage.srcset.length - 1) {
                  className = `${
                    data.backgroundImage.srcset.at(index - 1)?.media
                  }:hidden`;
                } else {
                  className = `hidden ${source.media}:block ${
                    data?.backgroundImage.srcset.at(index - 1)?.media
                  }:hidden`;
                }

                // TODO: Improve this to avoid rendering twice the number of images just to use only the last one
                if (variant === 'dynamic' && slide.columns.length === 1) {
                  return (
                    <Fragment key={`bc_image_${index}`}>
                      <div
                        className={`${className} pointer-events-none relative h-full w-full max-sm:hidden`}
                      >
                        <Image
                          src={source.src}
                          alt=""
                          fill
                          draggable={false}
                          loading="eager"
                          priority={index === 0}
                          className="object-cover object-center"
                        />
                      </div>

                      <Image
                        src={source.src}
                        alt=""
                        sizes="100vw"
                        style={{
                          width: '100%',
                          height: 'auto',
                        }}
                        width={source.width}
                        height={source.height}
                        draggable={false}
                        loading="eager"
                        priority={index === 0}
                        className={`object-cover object-center ${className} pointer-events-none relative`}
                      />
                    </Fragment>
                  );
                }

                if (variant !== 'dynamic') {
                  return (
                    <div
                      key={`bc_image_${index}`}
                      className={`${className} pointer-events-none relative h-full w-full`}
                    >
                      <Image
                        src={source.src}
                        alt=""
                        fill
                        draggable={false}
                        loading="eager"
                        priority={index === 0}
                        className="object-cover object-center"
                      />
                    </div>
                  );
                }
              })}

              <div
                className={`${
                  data.backgroundImage.srcset.length > 0
                    ? `${data.backgroundImage.srcset.at(-1)?.media}:hidden`
                    : ''
                } pointer-events-none relative h-full w-full`}
              >
                <Image
                  src={data.backgroundImage.src}
                  alt=""
                  fill
                  draggable={false}
                  loading="eager"
                  priority={index === 0}
                  className="object-cover object-center"
                />
              </div>
            </div>
          )}

          {data.overlay ? (
            <div
              className="absolute inset-0 bg-black"
              style={{ opacity: data.overlay }}
            />
          ) : null}

          <div
            className={oneLine`u-wrapper
            ${slide.columns.length > 1 ? 'md:max-w-[580px]' : 'max-w-[1160px]'}
            ${slide.columns.length === 1 ? 'mx-auto' : 'm-0'}
            ${index === 0 ? 'self-end' : 'self-start'}
            ${data.align ?? ''}
            ${data.justify ?? ''}
            ${data.textAlign ?? ''}
            ${
              variant === 'dynamic' && slide.columns.length === 1
                ? 'max-sm:absolute'
                : ''
            }
            flex h-full flex-col py-10`}
          >
            <div
              className={`flex flex-col gap-y-4 px-4 md:w-full md:max-w-[482px] xl:px-0 ${
                column.negative ? 'text-typo-alternative' : ''
              }`}
            >
              {index === 1 && slide.separadorRasgado && (
                <Fragment key="paper">
                  <PaperTextureRight
                    color={slide.colorSeparador}
                    width={25}
                    className="absolute bottom-0 left-0 top-0 z-10 hidden md:block"
                  />

                  {variant === 'marcas' ? (
                    <PaperTextureTop
                      color={slide.colorSeparador}
                      height={20}
                      className="absolute bottom-0 left-0 right-0 md:hidden"
                    />
                  ) : (
                    <PaperTextureBottom
                      color={slide.colorSeparador}
                      height={20}
                      className="absolute left-0 right-0 top-0 md:hidden"
                    />
                  )}
                </Fragment>
              )}

              {column.content
                .filter(Boolean)
                .map((item: any, index: number) => {
                  const type = item.fieldGroupName
                    .split('_')
                    .at(-1)
                    .toLowerCase();

                  switch (type) {
                    case 'pretitle':
                      return (
                        <Hero.Pretitle key={index}>
                          {item.pretitle}
                        </Hero.Pretitle>
                      );
                    case 'title':
                      if (item.mobileTitle) {
                        return (
                          <Fragment key={index}>
                            <Hero.Title
                              as={item.h1 ? 'h1' : 'span'}
                              className="max-md:hidden"
                            >
                              <div
                                className={`${
                                  variant === 'slider' && 'pr-6 md:pr-0'
                                }`}
                                dangerouslySetInnerHTML={{
                                  __html: item.title,
                                }}
                              />
                            </Hero.Title>
                            <Hero.Title
                              as={item.h1 ? 'h1' : 'span'}
                              className="md:hidden"
                            >
                              <div
                                className={`${
                                  variant === 'slider' && 'pr-6 md:pr-0'
                                }`}
                                dangerouslySetInnerHTML={{
                                  __html: item.mobileTitle,
                                }}
                              />
                            </Hero.Title>
                          </Fragment>
                        );
                      } else {
                        return (
                          <Hero.Title key={index} as={item.h1 ? 'h1' : 'span'}>
                            <div
                              className={`${
                                variant === 'slider' && 'pr-6 md:pr-0'
                              }`}
                              dangerouslySetInnerHTML={{
                                __html: item.title,
                              }}
                            />
                          </Hero.Title>
                        );
                      }
                    case 'description':
                      if (item.mobileDescription) {
                        return (
                          <Fragment key={index}>
                            <Hero.Description className="max-md:hidden">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              />
                            </Hero.Description>

                            <Hero.Description className="md:hidden">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.mobileDescription,
                                }}
                              />
                            </Hero.Description>
                          </Fragment>
                        );
                      } else {
                        return (
                          <Hero.Description key={index}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            />
                          </Hero.Description>
                        );
                      }
                    case 'button':
                      return (
                        <Hero.Buttons key={index}>
                          {item.button?.map(
                            (button: WpButton, index: number) => (
                              <NextLink
                                href={removeBaseURL(button?.link?.url ?? '#')}
                                passHref
                                key={index}
                              >
                                <Button
                                  as="a"
                                  label={button.label}
                                  style={button.style}
                                  size={button.size}
                                  negative={button.negativo}
                                  onClick={() => {
                                    gtmPush({
                                      event: 'select_promotion',
                                      items: normalizeItem(
                                        {},
                                        'banner_01',
                                        'home_banner',
                                      ),
                                    });
                                  }}
                                />
                              </NextLink>
                            ),
                          )}
                        </Hero.Buttons>
                      );
                    case 'image':
                      return (
                        item.image?.sourceUrl && (
                          <Hero.Image
                            key={index}
                            src={item.image.sourceUrl}
                            width={item.image?.mediaDetails?.width}
                            height={item.image?.mediaDetails?.height}
                            alt={item.image?.altText ?? ''}
                          />
                        )
                      );
                  }
                })}

              {column.logo && (
                <Hero.Logo
                  key={index + 1}
                  src={column.logo.sourceUrl}
                  width={column.logo?.mediaDetails.width}
                  height={column.logo?.mediaDetails.height}
                  alt={column.logo?.altText ?? ''}
                />
              )}
            </div>
          </div>
        </div>
      );
    })}
  </div>
);

HeroComplete.displayName = 'HeroComplete';
