import { Slide } from './Slide';
import { SliderHero } from './SliderHero';

export type TypeSliderHero = typeof SliderHero & {
  Slide: typeof Slide;
};

(SliderHero as TypeSliderHero).Slide = Slide;
Slide.displayName = 'SlideHero.Slide';

export type { TypeSliderHeroProps, TypeSlideProps } from './types';
export default SliderHero as TypeSliderHero;
